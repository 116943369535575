<template>
    <div>
        <!-- Page Title
		============================================= -->

		<section id="page-title" class="page-title-parallax page-title-center page-title-dark include-header" style="background-image: linear-gradient(to top, rgba(254,150,3,0.5), #39384D), url('/assets/images/faqs-title.jpg'); background-size: cover; padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">
			<div id="particles-line"></div>

			<div class="container clearfix mt-4">
				<div class="badge badge-pill border border-light text-light">All your Questions answered in one place</div>
				<h1>Frequently Asked Questions</h1>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link to="/"><a>Home</a></router-link></li>
					<li class="breadcrumb-item active" aria-current="page">FAQs</li>
				</ol>
			</div>

		</section><!-- #page-title end -->

		<!-- Content
		============================================= -->
		<section id="content">

			<div class="content-wrap pb-0">

				<div class="container bottommargin">
					<div class="row justify-content-center">
						<div class="col-xl-6 col-lg-8 text-center">
							<div class="heading-block border-bottom-0 center mx-auto">
								<div class="badge badge-pill badge-default">How can we help you?</div>
								<h3 class="nott ls0 mb-3">Frequently Asked Questions</h3>
							</div>
						</div>

						<div class="clear"></div>

						<!-- Post Content
						============================================= -->
						<div class="col-lg-10">

							<div class="grid-filter-wrap justify-content-center">
								<ul class="grid-filter style-4 customjs">

									<li class="activeFilter"><a class="font-weight-semibold" data-filter="all">All</a></li>
									<!-- <li><a href="#" class="font-weight-semibold" data-filter=".faq-marketplace">Marketplace</a></li>
									<li><a href="#" class="font-weight-semibold" data-filter=".faq-authors">Authors</a></li>
									<li><a href="#" class="font-weight-semibold" data-filter=".faq-legal">Legal</a></li>
									<li><a href="#" class="font-weight-semibold" data-filter=".faq-itemdiscussion">Item Discussion</a></li>
									<li><a href="#" class="font-weight-semibold" data-filter=".faq-affiliates">Affiliates</a></li>
									<li><a href="#" class="font-weight-semibold" data-filter=".faq-miscellaneous">Miscellaneous</a></li> -->

								</ul>
							</div>

							<div class="clear"></div>

							<div id="faqs" class="faqs">

								<div class="toggle faq pb-3 mb-4">
									<div class="toggle-header">
										<div class="toggle-icon color">
											<i class="toggle-closed icon-question-sign"></i>
											<i class="toggle-open icon-question-sign"></i>
										</div>
										<div class="toggle-title font-weight-semibold pl-1">
											How can I get started? 
										</div>
										<!-- <div class="toggle-icon color">
											<i class="toggle-closed icon-chevron-down text-black-50"></i>
											<i class="toggle-open icon-chevron-up color"></i>
										</div> -->
									</div>
									<div class="text-black-50 pl-4">
										The first step is to have a call with our team and describe the work you're looking to offshore. Our leadership team can help you determine the number of people needed for the coverage you're looking for and the skillset needed for the work you're looking to assign to your offshore team members. 
									</div>
								</div>

								<div class="toggle faq pb-3 mb-4">
									<div class="toggle-header">
										<div class="toggle-icon color">
											<i class="toggle-closed icon-group"></i>
											<i class="toggle-open icon-group"></i>
										</div>
										<div class="toggle-title font-weight-semibold pl-1">
											How quickly can eFlex build a team for me?
										</div>
										<!-- <div class="toggle-icon color">
											<i class="toggle-closed icon-chevron-down text-black-50"></i>
											<i class="toggle-open icon-chevron-up color"></i>
										</div> -->
									</div>
									<div class="text-black-50 pl-4">
										If you're looking to launch a team of 2-4 people, eFlex can get you up an running within a month. Larger teams will require a project manager, or team lead, which will inevitably increase the launch timeline; however, eFlex has successfully launched 10+ person teams in less than 4 weeks. 
									</div>
								</div>

								<div class="toggle faq pb-3 mb-4">
									<div class="toggle-header">
										<div class="toggle-icon color">
											<i class="toggle-closed icon-money"></i>
											<i class="toggle-open icon-money"></i>
										</div>
										<div class="toggle-title font-weight-semibold pl-1">
											What is your pricing model? 
										</div>
										<!-- <div class="toggle-icon color">
											<i class="toggle-closed icon-chevron-down text-black-50"></i>
											<i class="toggle-open icon-chevron-up color"></i>
										</div> -->
									</div>
									<div class="text-black-50 pl-4">
										Pricing will vary based on the skill set required to fulfill the duties that you seek. However, there are two service types offered. The first is a dedicated offering where a team member will spend all of their working hours on your projects. The second is a shared resource offering where a team member will spend, at most, 20 hours per week on your projects. The second offering is at a discounted rate compared to the dedicated team member service.
									</div>
								</div>

								<div class="toggle faq pb-3 mb-4">
									<div class="toggle-header">
										<div class="toggle-icon color">
											<i class="toggle-closed icon-user-clock"></i>
											<i class="toggle-open icon-user-clock"></i>
										</div>
										<div class="toggle-title font-weight-semibold pl-1">
											What hours can I expect for coverage?
										</div>
										<!-- <div class="toggle-icon color">
											<i class="toggle-closed icon-chevron-down text-black-50"></i>
											<i class="toggle-open icon-chevron-up color"></i>
										</div> -->
									</div>
									<div class="text-black-50 pl-4">
										eFlexervices is a 24/7/365 business. We can help you during US hours when volumes are high, and during non-business hours to provide support and services for global customers. We can also work during US holidays to ensure business continuation for you.
									</div>
								</div>

								<div class="toggle faq pb-3 mb-4">
									<div class="toggle-header">
										<div class="toggle-icon color">
											<i class="toggle-closed icon-world"></i>
											<i class="toggle-open icon-world"></i>
										</div>
										<div class="toggle-title font-weight-semibold pl-1">
											Do payments have to be sent overseas?
										</div>
										<!-- <div class="toggle-icon color">
											<i class="toggle-closed icon-chevron-down text-black-50"></i>
											<i class="toggle-open icon-chevron-up color"></i>
										</div> -->
									</div>
									<div class="text-black-50 pl-4">
										Payments occur in the U.S. through our corporation in San Mateo, California. Invoices will be sent via bill.com.
									</div>
								</div>

								<div class="toggle faq pb-3 mb-4">
									<div class="toggle-header">
										<div class="toggle-icon color">
											<i class="toggle-closed icon-language"></i>
											<i class="toggle-open icon-language"></i>
										</div>
										<div class="toggle-title font-weight-semibold pl-1">
											Do eFlex team members speak English? 
										</div>
										<!-- <div class="toggle-icon color">
											<i class="toggle-closed icon-chevron-down text-black-50"></i>
											<i class="toggle-open icon-chevron-up color"></i>
										</div> -->
									</div>
									<div class="text-black-50 pl-4">
										Yes, the Philippines has one of the largest populations of English speaking citizens in the world. More than half of the population speaks English fluently. Business and government affairs are done in English.
									</div>
								</div>
							</div>
						</div>
					</div><!-- .postcontent end -->
				</div>

				<div class="clear"></div>
				<Contact/>
			</div>
		</section><!-- #content end -->

    </div>
</template>

<script>
import Contact from '@/components/ContactForm'

export default {
    name: 'FAQ',
	data() {
		return {
			// a1: {
			// 	display: 'none'
			// },
			// a2: {
			// 	display: 'none'
			// },
			// a3: {
			// 	display: 'none'
			// },
			// a4: {
			// 	display: 'none'
			// },
			// a5: {
			// 	display: 'none'
			// },
			// a6: {
			// 	display: 'none'
			// },
		}
	},
    components: {
        Contact
    },
	methods: {
		// q1: function() {
		// 	if ( this.a1.display == 'none' ) {
		// 		this.a1.display = 'block'
		// 	} else {
		// 		this.a1.display = 'none'
		// 	}
		// },
		// q2: function() {
		// 	if ( this.a2.display == 'none' ) {
		// 		this.a2.display = 'block'
		// 	} else {
		// 		this.a2.display = 'none'
		// 	}
		// },
		// q3: function() {
		// 	if ( this.a3.display == 'none' ) {
		// 		this.a3.display = 'block'
		// 	} else {
		// 		this.a3.display = 'none'
		// 	}
		// },
		// q4: function() {
		// 	if ( this.a4.display == 'none' ) {
		// 		this.a4.display = 'block'
		// 	} else {
		// 		this.a4.display = 'none'
		// 	}
		// },
		// q5: function() {
		// 	if ( this.a5.display == 'none' ) {
		// 		this.a5.display = 'block'
		// 	} else {
		// 		this.a5.display = 'none'
		// 	}
		// },
		// q6: function() {
		// 	if ( this.a6.display == 'none' ) {
		// 		this.a6.display = 'block'
		// 	} else {
		// 		this.a6.display = 'none'
		// 	}
		// },
	},
	mounted() {
		window.scrollTo(0, 0)
	}
}
</script>